import React from "react";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import BarGraph from "./BarGraph";
const SummaryChart = () => {
  return (
    <div className="chart">
      <div className="chart-bar-graph">
        <div className="chart-section">
          <div className="chart-type">
            <p>Chart Type</p>
            <FormControl sx={{ m: 1, minWidth: 110 }}>
              <Select
                defaultValue={1}
                // onChange={handlePaginationChange}
                autoWidth
                style={{
                  color: "#7F00BB",
                  background: "#F8F9FA",
                  height: "35px",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "30px",
                }}
              >
                <MenuItem value={1}>Bar graph</MenuItem>\{" "}
                <MenuItem value={2}>Bar graph</MenuItem>
                <MenuItem value={3}>Bar graph</MenuItem>
              </Select>
            </FormControl>
          </div>

          <div className="view">
              <p>View data</p>
            <FormControl sx={{ m: 1, minWidth: 100 }}>
              <Select
                defaultValue={1}
                // onChange={handlePaginationChange}
                autoWidth
                style={{
                  color: "#7F00BB",
                  background: "#F8F9FA",
                  height: "35px",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "30px",
                }}
              >
                <MenuItem value={1}>University</MenuItem>
                <MenuItem value={2}>University</MenuItem>
                <MenuItem value={3}>University</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div style={{ height: "400px", width: "700px", background: "#F8F9FA" }}>
          <BarGraph />
        </div>
      </div>
    </div>
  );
};

export default SummaryChart;
