import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import { getJobsSummary } from '../../redux/Actions/jobActions';
const UniversityCard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobSummary, loading1, error1 } = useSelector(
    (state: any) => state.jobSummary
  );
  //   let group="university"
  //   let count1 =0;
  //   useEffect(() => {
  //     dispatch(JobsSummary(`${group}`));
  //   }, []);
  return (
    <div>
      <h1>{jobSummary?.records?.length}</h1>
    </div>
  );
};

export default UniversityCard;
