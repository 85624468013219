import React from "react";

const WorkShop = () => {
  return (
    <div className="workShop">
        
      <div className="workshop-left">
         
        <div className="head">
          <p>Workshop name</p>
          <p>Venue</p>
          <p >Firms</p>
          <p>Date</p>
          <p style={{marginRight:"-100px"}}>Attendees</p>
        </div>

        <div className="card">
          <p className="event">Elevate @ Yale - Investment Banking and Private
Equity Workshop</p>
          <p>Zoom</p>
          <p>KKR</p>
          <p>11th march 2022</p>
          <p className="button">View list</p>
        </div>
        <div className="card">
          <p className="event">Elevate @ Yale - Investment Banking and Private
Equity Workshop</p>
          <p>Zoom</p>
          <p>KKR</p>
          <p>11th march 2022</p>
          <p className="button">View list</p>
        </div>
        <div className="card">
          <p className="event">Elevate @ Yale - Investment Banking and Private
Equity Workshop</p>
          <p>Zoom</p>
          <p>KKR</p>
          <p>11th march 2022</p>
          <p className="button">View list</p>
        </div>
        <div className="card">
          <p className="event">Elevate @ Yale - Investment Banking and Private
Equity Workshop</p>
          <p>Zoom</p>
          <p>KKR</p>
          <p>11th march 2022</p>
          <p className="button">View list</p>
        </div>
      </div>
      <div className="right">
        <div className="head">
          <p>Applicants</p>
        </div>

        <div className="card">
          <p>University name</p>

          <p className="button">190+</p>
        </div>
        <div className="card">
          <p>University name</p>

          <p className="button">190+</p>
        </div>
        <div className="card">
          <p>University name</p>

          <p className="button">190+</p>
        </div>
      </div>
    </div>
  );
};

export default WorkShop;
