import { Chip } from "@mui/material";

const AffinityChips: React.FC<{ affinity: string }> = ({ affinity }) => {
  return (
    <>
      <Chip
        label={affinity}
        // color={randomCol[params?.row?.random]}
        style={
          affinity === "Women"
            ? {
                backgroundColor: "rgba(127,0,187,0.2)",
                color: "rgb(127,0,187)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "Black"
            ? {
                backgroundColor: "rgba(32,87,138,0.2)",
                color: "rgb(32,87,138)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "Latinx"
            ? {
                backgroundColor: "rgba(204,122,100,0.2)",
                color: "rgb(204,122,100)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "Asian"
            ? {
                backgroundColor: "rgba(183,86,169,0.2)",
                color: "rgb(183,86,169)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "Multi-Racial"
            ? {
                backgroundColor: "rgba(255,196,3,0.2)",
                color: "rgb(142,109,0)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "LGBTQ+"
            ? {
                backgroundColor: "rgba(251,97,7,0.2)",
                color: "rgb(251,97,7)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "Disability"
            ? {
                backgroundColor: "rgba(62,194,0,0.2)",
                color: "rgb(62,194,0)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "Other Diversity"
            ? {
                backgroundColor: "rgb(244,230,219)",
                color: "rgb(146,129,119)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "None of the Above"
            ? {
                backgroundColor: "rgba(132,104,245,0.2)",
                color: "rgb(132,104,245)",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : affinity === "Prefer not to say"
            ? {
                backgroundColor: "#999999",
                color: "#EEEEEE",
                fontSize: "14px",
                borderRadius: "10px",
              }
            : {}
        }
      />
    </>
  );
};

export default AffinityChips;
