import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

import { useDispatch, useSelector } from "react-redux";

import { forgetMail } from "../../redux/Actions/userActions";

const styles = makeStyles((theme) => ({
  paper: {
    padding: "50px",
    textAlign: "center",
    borderRadius: 18,
    background: "rgba(255, 255, 255, 0.5)",
    boxShadow: "0 4px 22px rgba(0, 0, 0, 0.1)",
    marginTop: "15vh",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

let correctEmail = new RegExp(
  "^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$"
);

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [view, setView] = useState(false);
  const classes = styles();
  const dispatch = useDispatch();

  const sendEmail = () => {
    if (email !== "" && email.includes("@") && email.includes(".")) {
      dispatch(forgetMail(email));
      setView(true);
    }
  };
  return (
    <Grid container spacing={3} className={classes.center}>
      <Grid item xs={12} sm={12} md={7} lg={7}>
        <Paper elevation={3} className={classes.paper}>
          <Typography variant="h4" component="h3" gutterBottom>
            Forgot Password?
          </Typography>
          <Typography component="p" gutterBottom>
            Please enter the email to find your account
          </Typography>
          <TextField
            label="Email"
            variant="outlined"
            type="email"
            error={!correctEmail.test(email) && email !== ""}
            fullWidth
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            sx={{ margin: "30px auto", width: "60%", display: "block" }}
          />
          {view ? (
            <Alert severity="success">An E-mail has been sent to this E-mail ID if it exists in our USER data</Alert>
          ) : (
          <Button variant="contained" color="primary" onClick={sendEmail}>
            Submit
          </Button>)}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default ForgetPassword