import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import {
  FormControl,
  Modal,
  Select,
  MenuItem,
  Box,
  Alert,
  Button,
  Chip,
  TextField,
} from "@mui/material";
import RatingTab from "./RatingTab";
import {
  enableScore,
  getScoreParamList,
  putScoreParamUpdate,
  putScoreUpdate,
  putStatusUpdate,
  getCandidatesDetails
} from "../../redux/Actions/jobActions";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import filter from "../../assets/images/score/filter.png";
import illus from "../../assets/images/score/illus.png";

const CandidateScoreCard: React.FC<{
  data: any;
  index: number;
  maxLength: number;
  setIndex: any;
}> = ({ data, index, maxLength, setIndex }) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  
  const [status, setStatus] = useState(0);
  const [score, setScore] = useState<any[]>([]);
  const [scoreList, setScoreList] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [error, setError] = useState({ status: false, message: "" });

  const { scoreParams } = useSelector((state: any) => state.scoreJobParam);
  const { success } = useSelector((state: any) => state.scoreEnable);

  let applicationId = searchParams.get("applicationId")

  useEffect(() => {
    if (success) {
      dispatch(getCandidatesDetails(applicationId))
    }
  }, [success])

  useEffect(() => {
    dispatch(getScoreParamList());
  }, []);

  useEffect(() => {
    if (status !== data?.application_status) {
      setStatus(data.application_status);
    }
    if (
      JSON.stringify(score) !==
      JSON.stringify(data?.job_score_parameters?.length)
    ) {
      setScore(data?.job_score_parameters);
      setScoreList(data?.job_score_parameters);
    }
  }, [data]);

  const statusHandler = (e: any) => {
    setStatus(e.target.value);
    data.application_status = Number(e.target.value);
    dispatch(
      putStatusUpdate({
        _id: data?._id,
        application_status: e.target.value,
      })
    );
  };

  const scoreHandler = (n: number, newValue: number) => {
    let scores = score.slice();

    scores[n].value = newValue;
    data.job_score_parameters[n].value = newValue;
    setScore(scores);
    dispatch(
      putScoreUpdate({
        _id: data?._id,
        job_score_parameters: scores,
      })
    );
  };

  const totalScore = () => {
    let scores = score?.slice();
    let total = 0;

    scores?.forEach((score: any) => (total += score.value * score.weight));

    return Math.round(total * 100) / 100;
  };

  const totalWeight = () => {
    let scores = scoreList?.slice();
    let total = 0;

    scores?.forEach((score: any) => (total += score.weight));

    return Math.round(total * 100);
  };

  const handleMultipleChoice = (event: any) => {
    let scores = scoreList.slice();

    if (
      scores?.find((sc: any) => sc.name.toLowerCase() === event.toLowerCase())
    ) {
      scores = scores.filter(
        (item: any) => item.name.toLowerCase() !== event.toLowerCase()
      );
    } else {
      scores.push({
        custom: false,
        name: event.toLowerCase(),
        key: event.toLowerCase(),
        min: 0,
        max: 10,
        value: 0,
        weight: 0,
        required: true,
      });
    }

    setScoreList(scores);
  };

  const addCustomParamHandler = (e: any) => {
    let scores = scoreList.slice();
    scores.push({
      custom: true,
      name: e.target.value.toLowerCase(),
      key: e.target.value.toLowerCase(),
      min: 0,
      max: 10,
      value: 0,
      weight: 0,
      required: true,
    });

    setScoreList(scores);
    setAdd(false);
  };

  const orderHandler = (result: any) => {
    const items = Array.from(scoreList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setScoreList(items);
  };

  const saveHandler = () => {
    if (totalWeight() > 100 || totalWeight() < 100) {
      setError({ status: true, message: "Total weight must be 100%" });
    } else {
      setScore(scoreList);
      dispatch(
        putScoreParamUpdate({
          _id: data?.job_id,
          job_score_parameters: scoreList,
        })
      );
      setOpen(false);
    }
  };

  const closeHandler = () => {
    setScoreList(score);
    setOpen(false);
    setAdd(false);
  };

  const weightHandler = (e: any, n: number) => {
    let scores = scoreList?.slice();
    scores[n].weight =
      Number(e.target.value) === 0 ? "" : Number(e.target.value) / 100;

    setScoreList(scores);
  };

  const movLeft = () => {
    if (index > 0) {
      setIndex((prev: number) => prev - 1);
    } else if (index === 0) {
      setIndex(maxLength - 1);
    }
  };

  const movRight = () => {
    if (index < maxLength - 1) {
      setIndex((prev: number) => prev + 1);
    } else if (index < maxLength) {
      setIndex(0);
    }
  };

  const enScore = () => {
    dispatch(enableScore({
      _id: data?.job_id,
      job_score_enabled: true,
    }))
  }

  return (
    <div className="score-card">
      <div className="index">
        <Button onClick={movLeft}>
          <ChevronLeftIcon
            style={{ fontSize: 40, color: "#7F00BB", cursor: "pointer" }}
          />
        </Button>
        <h2>
          Candidate {index + 1}/{maxLength}
        </h2>
        <Button onClick={movRight}>
          <ChevronRightIcon
            style={{ fontSize: 40, color: "#7F00BB", cursor: "pointer" }}
          />
        </Button>
      </div>
      <div className="status">
        <h2>Status</h2>
        <FormControl fullWidth>
          {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={status}
            onChange={statusHandler}
            size="small"
            style={{ background: "white" }}
          >
            <MenuItem value={0}>Not Yet Reviewed</MenuItem>
            <MenuItem value={1}>Decline</MenuItem>
            <MenuItem value={2}>Maybe</MenuItem>
            <MenuItem value={3}>Invite for Interview</MenuItem>
            <MenuItem value={4}>Offer</MenuItem>
            <MenuItem value={5}>Offer Accepted</MenuItem>
          </Select>
        </FormControl>
      </div>

      {data?.job?.job_score_enabled ? (
        <div className="scores">
          <div className="top-bar">
            <h2>Score</h2>
            <EditIcon
              onClick={() => setOpen(true)}
              style={{ fontSize: 22, cursor: "pointer" }}
            />
          </div>
          {score !== null &&
            score?.length !== 0 &&
            score?.map((record: any, index: number) => (
              <RatingTab
                key={index}
                num={index}
                data={record}
                score={scoreHandler}
              />
            ))}
        </div>
      ) : (
        <div style={{ marginTop: 40 }} className="enable">
          <Button onClick={enScore}>Enable Scores</Button>
        </div>
      )}

      {data?.job?.job_score_enabled && (
        <div className="total-card">
          <p>Total Score</p>
          <h2>{totalScore()}/10</h2>
        </div>
      )}

      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={closeHandler}
      >
        <Box className="modal">
          <h2>Candidate Scoring Modifier</h2>
          <div className="chips">
            {scoreParams?.map((score: any) => (
              <Chip
                label={score.index}
                className={
                  scoreList?.find(
                    (sc: any) =>
                      sc.name.toLowerCase() === score.index.toLowerCase()
                  )
                    ? "chip filled"
                    : "chip outlined"
                }
                onClick={() => handleMultipleChoice(score.index)}
                onDelete={() => handleMultipleChoice(score.index)}
                deleteIcon={
                  scoreList?.find(
                    (sc: any) =>
                      sc.name.toLowerCase() === score.index.toLowerCase()
                  ) ? (
                    <CheckCircleOutlineIcon style={{ color: "#ffffff" }} />
                  ) : (
                    <AddCircleOutlineIcon style={{ color: "#333333" }} />
                  )
                }
              />
            ))}
            {scoreList
              ?.filter((obj) => obj.custom === true)
              ?.map((sco: any) => (
                <Chip
                  label={sco.name}
                  className={
                    scoreList?.find(
                      (sc: any) =>
                        sc.name.toLowerCase() === sco.name.toLowerCase()
                    )
                      ? "chip filled"
                      : "chip outlined"
                  }
                  onClick={() => handleMultipleChoice(sco.name)}
                  onDelete={() => handleMultipleChoice(sco.name)}
                  deleteIcon={
                    scoreList?.find(
                      (sc: any) =>
                        sc.name.toLowerCase() === sco.name.toLowerCase()
                    ) ? (
                      <CheckCircleOutlineIcon style={{ color: "#ffffff" }} />
                    ) : (
                      <AddCircleOutlineIcon style={{ color: "#333333" }} />
                    )
                  }
                />
              ))}
            <Chip
              label="Add more"
              className="chip outlined"
              onClick={() => setAdd(true)}
              onDelete={() => setAdd(true)}
              deleteIcon={<AddCircleOutlineIcon style={{ color: "#333333" }} />}
            />
            {add && (
              <TextField
                size="small"
                variant="outlined"
                style={{ maxWidth: 150, display: "block", marginLeft: 5 }}
                onKeyPress={(e) =>
                  e.key === "Enter" && addCustomParamHandler(e)
                }
              />
            )}
          </div>

          <div className="container">
            <div className="left">
              <h3>Traits</h3>
              <DragDropContext onDragEnd={orderHandler}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {scoreList?.map((score: any, index: number) => (
                        <Draggable
                          key={index}
                          index={index}
                          draggableId={score.name}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={filter}
                                  alt=""
                                  style={{ height: 18, marginRight: 40 }}
                                />{" "}
                                <span
                                  style={{
                                    textTransform: "capitalize",
                                    fontWeight: "500",
                                    fontSize: 15,
                                  }}
                                >
                                  {score.name}
                                </span>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "flex-end",
                                }}
                              >
                                <TextField
                                  variant="standard"
                                  size="small"
                                  value={score.weight * 100}
                                  onChange={(e) => weightHandler(e, index)}
                                  style={{
                                    width: 60,
                                    padding: "0 20px",
                                    background: "#eee",
                                    borderRadius: 5,
                                  }}
                                />
                                <p
                                  style={{
                                    display: "inline-block",
                                    fontWeight: "500",
                                    fontSize: 20,
                                  }}
                                >
                                  %
                                </p>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            <div className="right">
              <div>
                <p>Total Weight (%)</p>
                <h2>{totalWeight()} / 100</h2>
              </div>
              <img className="illus" src={illus} alt="" />
            </div>
          </div>
          {error.status && <Alert severity="error">{error.message}</Alert>}

          <div className="btns">
            <Button onClick={closeHandler} variant="outlined">
              Cancel
            </Button>
            <Button onClick={saveHandler} variant="contained">
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default CandidateScoreCard;

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: "8px 16px",
  margin: `0 0 10px 0`,
  borderRadius: "6px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  minWidth: "250px",
  width: "100%",
  // columnGap: "40px",

  // change background colour if dragging
  background: isDragging ? "lightgray" : "white",

  // styles we need to apply on draggables
  ...draggableStyle,
});
