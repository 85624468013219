import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import Rating, { IconContainerProps } from "@mui/material/Rating";
import { styled } from "@mui/material/styles";
import { Close, Done } from "@mui/icons-material";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "#7F00BB",
  },
  "& .MuiRating-iconHover": {
    color: "#7F00BB",
  },
});

const customIcons: {
  [index: string]: {
    icon: React.ReactElement;
    label: string;
  };
} = {
  1: {
    icon: (
      <span className="icon-num_1">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: (
      <span className="icon-num_2">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Dissatisfied",
  },
  3: {
    icon: (
      <span className="icon-num_3">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Neutral",
  },
  4: {
    icon: (
      <span className="icon-num_4">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <span className="icon-num_5">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
      </span>
    ),
    label: "Very Satisfied",
  },
  6: {
    icon: (
      <span className="icon-num_6">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Very Dissatisfied",
  },
  7: {
    icon: (
      <span className="icon-num_7">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Dissatisfied",
  },
  8: {
    icon: (
      <span className="icon-num_8">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
      </span>
    ),
    label: "Neutral",
  },
  9: {
    icon: (
      <span className="icon-num_9">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Satisfied",
  },
  10: {
    icon: (
      <span className="icon-num_10">
        <span className="path1"></span>
        <span className="path2"></span>
      </span>
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

const RatingTab: React.FC<{ data: any; score: any; num: number }> = ({
  data,
  score,
  num,
}) => {
  const [selected, setSelected] = useState(data.value);
  const [loading, setLoading] = useState(false);

  const { error, success } = useSelector((state: any) => state.scoreJob);

  useEffect(() => {
    if (error || success) {
      setLoading(false);
    }
  });

  useEffect(() => {
    if (selected !== data.value) {
      setSelected(data.value)
    }
  }, [data]);

  const valueHandler = (newValue: any) => {
    setSelected(newValue);
    score(num, newValue);
    setLoading(true);
  };

  if (!("boxShadow" in document.body.style)) {
    document.body.setAttribute("class", "noBoxShadow");
  }

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ textTransform: "capitalize" }}>
          {data?.name} {loading && <CircularProgress size="16px" />}{" "}
          {error && <Close style={{ color: "red", fontSize: "16px" }} />}{" "}
          {success && <Done style={{ color: "green", fontSize: "16px" }} />}
        </p>
        <p>{data?.weight * 100}%</p>
      </div>
      <StyledRating
        value={selected}
        onChange={(e, newValue) => valueHandler(newValue)}
        IconContainerComponent={IconContainer}
        max={10}
      />
    </div>
  );
};

export default RatingTab;
