import SideBar from "../Nav/SideBar";

export default function LayoutsSide(Component: React.ComponentType) {
  function LayoutFinnect(props: any) {
    return (
      <div className="portal">
        <SideBar />
        <div className="content-container">
          <Component {...props} />
        </div>
      </div>
    );
  }

  return LayoutFinnect;
}

