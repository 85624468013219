import React from "react";

import { SchoolOutlined, ApartmentOutlined } from "@mui/icons-material";

const BasicDetails: React.FC<{ data: any }> = ({ data }) => {
  return (
    <div className="basic-details">
      <div className="education">
        <h4>Education</h4>
        {data?.education?.map((edu: any, index: number) => (
          <div key={index} className="edu">
            <h5>
              <SchoolOutlined /> {edu.name}
            </h5>
            <h5>{edu.graduation_year}</h5>
          </div>
        ))}
      </div>

      <div className="hr" />

      <div className="experience">
        <h4>Experience</h4>
        {data?.work_experiences?.map((work: any, index: number) => (
          <div className="work">
            <div>
              <h5>{work.title}</h5>
              <p>{work.start_date.split("T")[0]}</p>
            </div>
            <div>
              <h6>
                <ApartmentOutlined /> {work.company}
              </h6>
            </div>
          </div>
        ))}
      </div>

      <div className="hr" />

      <div className="metrics">
        <h4>Metrics</h4>
        <div>
          <h5>
            UG GPA <span>{data?.gpa}</span>
          </h5>
          <h5>
            ACT <span>{data?.act === 0 ? "-" : data?.act}</span>
          </h5>
        </div>
        <div>
          <h5>
            SAT <span>{data?.sat === 0 ? "-" : data?.sat}</span>
          </h5>
          <h5>
            GMAT <span>{data?.gmat === 0 ? "-" : data?.gmat}</span>
          </h5>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;