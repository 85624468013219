import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React from "react";

const SummaryBanner = () => {
  return (
    <div className="summary-banner">
      <div className="dropdown-section">
        <div className="analytic-job">
          <div className="analytic">
            <FormControl sx={{ m: 1, minWidth: 230 }}>
              <Select
                defaultValue={1}
                // onChange={handlePaginationChange}
                autoWidth
                style={{
                  color: "#7F00BB",
                  background: "#F8F9FA",
                  height: "40px",
                  fontSize: "16px",
                  fontWeight: "600",
                }}
              >
                <MenuItem value={1}>Job board Analytics</MenuItem>
                <MenuItem value={2}>Job board Analytics</MenuItem>
                <MenuItem value={3}>Job board Analytics</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="job">
            <p>Workshop Data</p>
            <FormControl sx={{ m: 1, minWidth: 110 }}>
              <Select
                defaultValue={1}
                // onChange={handlePaginationChange}
                autoWidth
                style={{
                  color: "#7F00BB",
                  background: "#F8F9FA",
                  height: "35px",
                  fontSize: "14px",
                  fontWeight: "500",
                  borderRadius: "30px",
                }}
              >
                <MenuItem value={1}>Job name -1</MenuItem>\{" "}
                <MenuItem value={2}>Job name -1</MenuItem>
                <MenuItem value={3}>Job name -1</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className="Dail">
          <FormControl sx={{ m: 1, minWidth: 100 }}>
            <Select
              defaultValue={1}
              // onChange={handlePaginationChange}
              autoWidth
              style={{
                color: "#7F00BB",
                background: "#F8F9FA",
                height: "35px",
                fontSize: "14px",
                fontWeight: "500",
                borderRadius: "30px",
              }}
            >
              <MenuItem value={1}>Daily</MenuItem>
              <MenuItem value={2}>Daily</MenuItem>
              <MenuItem value={3}>Daily</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="heading-prograss">
        <h1 className="dashboard-heading">Dashboard</h1>

        <div className="prograss-section">
          <div className="applicant">
            <div className="text">
              <h2>Total Attendees</h2>
              <h1>1202</h1>
              <p>During 3 months</p>
            </div>
            <div className="prograss-bar">
              <Box sx={{ position: "relative", display: "inline-flex" }}>
                <CircularProgress
                  variant="determinate"
                  value={80}
                  style={{ width: "50px", color: "#7F00BB" }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: "absolute",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    variant="caption"
                    component="div"
                    color="text.secondary"
                    marginLeft={2.1}
                  >
                    {50}%
                  </Typography>
                </Box>
              </Box>
            </div>
          </div>

          <div className="interview">
            <h2>Recurring Attendees </h2>
            <div className="text-prograss">
              <div className="text">
                <h1>40</h1>
                <p>During 3 months</p>
              </div>
              <div className="prograss-bar">
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    variant="determinate"
                    value={60}
                    style={{ width: "50px", color: "#B756A9" }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                      marginLeft={2.1}
                    >
                      {50}%
                    </Typography>
                  </Box>
                </Box>
              </div>
            </div>
          </div>

          <div className="interview">
            <h2>Total number of universitites</h2>
            <div className="text-prograss">
              <div className="text">
                <h1>100</h1>
                <p>During 3 months</p>
              </div>
            </div>
          </div>

          <div className="post-club">
            <h2>Total Worshop posted</h2>

            <h1>4</h1>
            <p>During 3 months</p>
          </div>
          <div className="post-club">
            <h2>Total partner clubs</h2>

            <h1>45+</h1>
            <p>General info</p>
          </div>
        </div>
      </div>
      <hr className="underline" />
    </div>
  );
};

export default SummaryBanner;
